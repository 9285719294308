<template>
  <b-modal :id="id" class="modal-notification" hide-header hide-footer centered >
    <div>
      <div v-if="title" class="modal-header text-center">
        <h3 style="width: 100%">{{title}}</h3>
      </div>
      <div class="modal-body content_popup" v-html="content"></div>
      <div class="modal-footer text-center" style="justify-content: center">
        <b-button
            class="btn-primary"
            @click="closePopup()"
        >
          {{title_button}}
        </b-button>
        <template>
            <slot></slot>
        </template>
      </div>
    </div>
  </b-modal>
</template>

<script>
import * as constants from "@/core/config/constant";

export default {
  name: "Popup",
  props: {
    title:{
      type:String,
      default:'',
    },
    id:{
      type:String,
      default:'Popup',
    },
    content:{
      type:String,
      default:'',
    },
    title_button:{
      type:String,
      default: '',
    }
  },
  methods: {
    closePopup(){
      this.$bvModal.hide(this.id);
    },
  }
}
</script>

<style scoped lang="scss">
  .content_popup {
    word-break: break-all;
  }
</style>
<style>
.modal-content{
    border-radius: 15px;
    border: 2px solid #000000;
}
</style>
