<template>
    <div v-loading="loading">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header">
                <div class="card-title">
          <span class="card-icon">
            <i class="flaticon2-delivery-package text-primary"></i>
          </span>
                <h3 class="card-label" v-if="$route.name === 'form-tenant-add'"><b class="title-header">テナント設定</b></h3>
                <h3 class="card-label" v-else><b class="title-header">テナント詳細・編集</b></h3>
                </div>
            </div>
            <div class="card-body">
                <el-form
                    ref="ruleForm"
                    label-width="30%"
                    class="demo-ruleForm demo-form"
                    :model="ruleForm"
                    @keyup.enter.native="confirm_modal"
                >
                    <div class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <label  class="el-form-item__label text-left" style="width: 30%;">テナント名
                                <span class="error-message">*</span></label>
                            <el-form-item class="custom_reponsive" prop="tenant_name">
                                <el-input
                                    maxlength="40"
                                    placeholder="テナント名を入力"
                                    class="bg-gray-item"
                                    v-model="ruleForm.tenant_name"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <label  class="el-form-item__label text-left" style="width: 30%;">ステータス  <span class="error-message">*</span></label>
                            <el-form-item class="custom_reponsive" prop="tenant_status">
                                <el-select
                                    maxlength="40%"
                                    class="bg-gray-item"
                                    v-model="ruleForm.tenant_status"
                                    style="width: 100%"
                                    placeholder="選択してください"
                                >
                                    <el-option
                                        v-for="status in ArrStatus"
                                        :value="status.value"
                                        :label="status.label"
                                        :key="status.value"
                                    >{{ status.label }}</el-option
                                    >
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <label  class="el-form-item__label text-left" style="width: 30%;">ディレクトリ名
                                <span class="error-message">*</span></label>
                            <el-form-item class="custom_reponsive" prop="tenant_dir">
                                <el-input
                                    maxlength="40"
                                    class="bg-gray-item"
                                    placeholder="ディレクトリ名を入力"
                                    v-model="ruleForm.tenant_dir"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item prop="action" class="button-search" label-width="0px">
                        <el-button
                            class="ml-20 pl-10 pr-10"
                            type="primary"
                            @click.prevent="confirm_modal"
                        >入力完了</el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <Popup id="confirm" title="確認" :content="title" :title_button="close_popup">
            <b-button @click.prevent="actionTenant" class="btn-primary">{{ $t("YES") }}</b-button>
        </Popup>
        <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                   :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
            <template slot="header">
                <div class="textwidget text-center">
                    {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
                </div>
            </template>
        </my-dialog>
    </div>
</template>
<script>

import * as constants from "@/core/config/constant";
import { CREATE_TENANT, DETAIL_TENANT, UPDATE_TENANT } from "@/core/services/store/mtenant.module";
import Popup from "@/view/components/Popup";
import { mapActions } from "vuex";
export default {
    components: {
        Popup
    },
    data() {
        return {
            ruleForm: {
                tenant_name: "",
                tenant_dir: '',
                tenant_status: '',
            },
            constants: constants,
            loading: false,
            isSubmit: false,
            title: "",
            ArrStatus: [],
            close_popup: this.$t("close_popup"),
            not_permisson_popup: false
        };
    },
    created() {
        this.ArrStatus = this.constants.ROLE_STATUS;
        if(this.$route.name === 'edit-tenant') {
            const {tenant_id} = this.$route.params;
            this.getDetailTenant(tenant_id)
        }
        if (!this.hasPermissonCheck('tennant_flg')){
            this.not_permisson_popup = true
        }
    },
    methods: {
        ...mapActions([DETAIL_TENANT, UPDATE_TENANT]),
        async getDetailTenant(id) {
            try {
                this.loading = true;
                const data = await this[DETAIL_TENANT](id);
                this.ruleForm = {
                    tenant_name: data.tenant_name,
                    tenant_dir: data.tenant_dir,
                    tenant_status: data.tenant_status,
                }
            } catch (error) {
                this.notifyError(e);
                this.$bvModal.hide("confirm");
                this.loading = false;
            } finally {
                this.loading = false;
            }
        },
        actionTenant() {
            if(this.$route.name === 'edit-tenant') {
                this.updateTenantData()
            } else {
                this.createTenant();
            }
        },
        updateTenantData() {
            this.$refs["ruleForm"].validate( async (valid) => {
                if (valid) {
                    try {
                        this.loading = true;
                        const data = await this[UPDATE_TENANT]({...this.ruleForm, id: this.$route.params.tenant_id});
                        if (data && data.data && data.data.data) {
                            if (data.data.code === constants.STATUS_CODE.SUCCESS) {
                                this.notifySuccess(this.$t("UPDATE_SUCCESS"));
                                this.$router.push({
                                    name: "tenant-list",
                                })
                                return;
                            }
                            if (data.data.code === constants.STATUS_CODE.BAD_REQUEST) {
                                this.showErrorMessage(data.data.data, "ruleForm");
                                this.$bvModal.hide("confirm");
                            } else {
                                this.notifyError(data.message);
                                this.$bvModal.hide("confirm");
                                this.loading = false;
                            }
                        } else {
                            this.notifyError(this.$t("ERROR_SERVER"))
                            this.$bvModal.hide("confirm");
                            this.loading = false;
                        }
                    } catch (error) {
                        this.notifyError(e);
                        this.$bvModal.hide("confirm");
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.$bvModal.hide("confirm");
                }
            });
        },
        createTenant() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.turnOffErrorMessage("ruleForm");
                    this.$store.dispatch(CREATE_TENANT, this.ruleForm).then((data) => {
                        this.loading = false;
                        if (data && data.data && data.data.data) {
                            if (data.data.code === constants.STATUS_CODE.SUCCESS) {
                                this.notifySuccess(this.$t("REGISTER_SUCCESS"));
                                this.$router.push({
                                    name: "tenant-list",
                                })
                                return;
                            }
                            if (data.data.code === constants.STATUS_CODE.BAD_REQUEST) {
                                this.showErrorMessage(data.data.data, "ruleForm");
                                this.$bvModal.hide("confirm");
                            } else {
                                this.notifyError(data.message);
                                this.$bvModal.hide("confirm");
                                this.loading = false;
                            }
                        } else {
                            this.notifyError(this.$t("ERROR_SERVER"))
                            this.$bvModal.hide("confirm");
                            this.loading = false;
                        }
                    })
                        .catch((e) => {
                            this.notifyError(e);
                            this.$bvModal.hide("confirm");
                            this.loading = false;
                        });
                } else {
                    this.$bvModal.hide("confirm");
                }
            });
        },
        confirm_modal() {
            this.title = this.$t("TITLE_CONFIRM_TENANT",{tenant_name:this.ruleForm.tenant_name});
            this.$bvModal.show("confirm");
        }
    },
};
</script>

<style scoped lang="scss">
.card-custom {
    border-radius: 15px;
}

.card-custom > .card-header {
    padding: 0;
    margin: 0px 21px;
}
@media only screen and (max-width: 990px) and (min-width: 270px){
    .bg-gray-item, .el-input--suffix {
        width: 100%;
        float: right;
    }
    .el-form-item__content {
        width: 100% !important;
        float: right !important;
        margin-left: 0px !important;
    }
    .ml-20, .mx-20 {
        margin-left: 0px !important;
    }
    label.el-form-item__label.text-left {
        width: 50% !important;
    }
}
</style>
